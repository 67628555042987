import { useAuth0 } from '@auth0/auth0-react';
import React, { useEffect } from 'react';

import { GenericInLayoutPageLoading } from '../components/common/GenericFullPageLoading';

export default function ForceLoginPage() {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        loginWithRedirect({
            appState: {
                returnTo: '/dashboard',
            },
        });
    }, [loginWithRedirect]);

    // need something cleaner here
    return <GenericInLayoutPageLoading />;
}
